import React, { useEffect, useMemo } from 'react'
import { capitalize } from '@ecommerce/shared'
import { ProductViewNode } from '../../graphql/productView'
import {
  BreadcrumbsWrapper,
  ProductDetailWrapper,
  ProductInformationWrapper,
  Wrapper,
  WrapperDetail,
} from './index.styled'
import { useProduct } from './context/product.context'
import ProductInformation from './Information'
import ProductDetail from './Detail'
import AddProductButtonMobile from './Detail/AddProductButtonMobile'
import Breadcrumbs from '../Breadcrumbs'
import ImageModal from './Detail/ImageSlider/ImageModal'
import { useScroll } from './context/scroll.context'
import { DetailViewProduct } from './types'
import { FlatLocationProductCategory } from '../../types/PgPages'

interface Props {
  product: DetailViewProduct
  isLoading: boolean
  isError: boolean
  templateData: ProductViewNode['template']
  categories: FlatLocationProductCategory[]
}

const ProductView = (props: Props) => {
  const { categories, isError, isLoading, product, templateData } = props

  const { containerRef } = useScroll()
  const { setProduct, setIsError, setIsLoading, selectedImage, setTemplateData } = useProduct()

  const categorySlug = useMemo(() => {
    if (!product?.categoryName) return '#'
    const category = categories?.find((item) => item.mainCategoryNames.includes(product?.categoryName || ''))
    const slug = category ? category.slug : '#'

    return `category/${slug}`
  }, [product?.categoryName])

  const items = [
    {
      name: 'Inicio',
      url: `/`,
    },
    {
      name: capitalize(product?.categoryName ?? ''),
      url: `/${categorySlug}`,
    },
    {
      name: product?.title,
      url: '',
    },
  ]

  useEffect(() => {
    if (product && templateData) {
      setProduct(product)
      setTemplateData(templateData)
      setIsLoading(isLoading)
      setIsError(isError)
    }
  }, [product, templateData, isLoading, isError])

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <WrapperDetail ref={containerRef}>
      <BreadcrumbsWrapper>
        <Breadcrumbs showBack={false} className="breadcrumbs" items={items} />
      </BreadcrumbsWrapper>
      <Wrapper>
        <ProductDetailWrapper>
          <ProductDetail />
        </ProductDetailWrapper>
        <ProductInformationWrapper>
          <ProductInformation />
        </ProductInformationWrapper>
        <AddProductButtonMobile />
      </Wrapper>
      {selectedImage && <ImageModal />}
    </WrapperDetail>
  )
}

export default ProductView
