import styled, { css } from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

const commonStyles = css`
  margin: 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme }) => theme.colors.black80};
  @media screen and (${breakpoints.desktop.min}) {
    gap: 8px;
  }
`

export const BrandName = styled.p`
  font-size: 13px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: -0.06px;
  ${commonStyles}
`

export const ProductName = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.254px;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.111px;
  }

  ${commonStyles}
`
