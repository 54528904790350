import React from 'react'
import { useProduct } from '../../context/product.context'
import { formatText } from '../../utils'
import { BrandName, ProductName, Wrapper } from './index.styled'

const BrandHeader = () => {
  const { product } = useProduct()

  return (
    <Wrapper>
      <BrandName>{formatText(product?.brandName || '')}</BrandName>
      <ProductName>{formatText(product?.title || '')}</ProductName>
    </Wrapper>
  )
}

export default BrandHeader
